export const round = (num: number) =>
  num
    .toFixed(7)
    .replace(/(\.[0-9]+?)0+$/, '$1')
    .replace(/\.0$/, '')
export const rem = (px: number) => `${round(px / 16)}rem`
export const unitLess = (lineHeight: number, fontSize: number) =>
  `${lineHeight / fontSize}/* ${lineHeight}px */`

export const formatAsLink = (str: string) =>
  str.toLowerCase().replaceAll(' ', '-').replaceAll(',', '')

export const htmlToString = (html: string) => {
  return html.replace(/<[^>]*>/g, '').replace(/&#(\d+);/g, function (_, dec: number) {
    return String.fromCharCode(dec)
  })
}
